<template>
  <div class="ecommerce-application">
    <b-overlay rounded="sm" :show="show">
      <div class="mt-0">
        <div class="checkout-items order-last order-lg-first">
          <b-card>
            <form ref="formTour">
              <input type="hidden" name="tournament_id" v-model="tournament.id">
              <input type="hidden" name="player_id" :value="user.player.id">
              <h5 class="card-title ml-2"> <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                  stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                  <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                </svg>
                {{ tournament.name }}
                <b-badge :class="'color-' + (tournament.type == 0 ? 'american' : 'canarian')">{{ tournament.type == 0 ?
                  'Americana' : 'Canaria' }} </b-badge>
              </h5>
              <h4 class="ml-2">Fecha y hora: {{ tournament.tournament_date ? formatDate(tournament.tournament_date) :
                ''}} {{ tournament.tournament_hour ? formattedTime(tournament.tournament_hour) : '' }} </h4>
              <h4 class="ml-2">Nivel: {{ tournament.level }}</h4>
              <h4 class="ml-2">Nº pistas: {{ tournament.number_courts }}</h4>
              <h4 class="ml-2" v-if="tournament.group_id">Grupo: {{ tournament.group.name }}</h4>
              <h4 class="ml-2">Precio: {{ tournament.price }}</h4>
              <h4 class="ml-2">Premio: {{ tournament.prize }}</h4>

            </form>
            <p class="ml-2 mt-2 text-success" v-if="registered == true">
              <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              Ya estás inscrito/a a este torneo
            </p>
          </b-card>
        </div>

      </div>

      <div v-if="!registered" class="mb-2">
        <b-button variant="success" block @click="openRegisterModal">Inscribirme</b-button>

      </div>
      <div>
        <a :href="'/players/' + tournament.id">
          <b-button variant="warning" block>Ver participantes</b-button>
        </a>
      </div>
    </b-overlay>

    <b-modal id="register-modal" title="Inscribirse a torneo" class="custom-modal " no-close-on-backdrop
      modal-class="margin-top">
      <p class="font-weight-bold">¿Estás sergur@ de inscribirte a este torneo?</p>
      <p class="mb-2" v-if="tournament.type == 0">Puedes elegir pareja de las siguientes maneras</p>
      <form ref="formRegister">
        <input type="hidden" name="tournament_id" :value="tournament.id">
        <input type="hidden" name="player_id" :value="user.player.id">
        <div v-if="!selected_player_available">
          <label v-if="!tournament.group_id" for="">Seleccione pareja según jugadores que aún no están inscritos</label>
          <label v-else for="">Seleccione pareja según jugadores del grupo asociado</label>
          <v-select v-if="tournament.type == 0" :options="players" placeholder="Seleccione pareja" label="title"
            :getOptionKey="getOptionKey" v-model="selected_player" name="second_player" />
        </div>

        <div v-if="!selected_player">
          <!-- <div v-if="available_players && available_players.length > 0 && !selected_player"> -->
          <label v-if="tournament.type == 0" class="mt-2" for="">Jugadores inscritos que buscan pareja</label>
          <v-select v-if="tournament.type == 0" :options="available_players"
            placeholder="Bolsa de jugadores disponibles" label="title" :getOptionKey="getOptionKey"
            v-model="selected_player_available" name="second_player" />

        </div>
      </form>
      <template #modal-footer="{ cancel }">
        <b-row class="w-100">
          <b-col md="4">
            <button class="btn btn-success btn-sm btn-block mb-1" @click="registerPlayer">Inscribirse</button>
          </b-col>
          <b-col md="4">
            <button class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
          </b-col>
        </b-row>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import vSelect from 'vue-select'
import moment from 'moment';
import { BFormTextarea, VBModal, BRow, BImg, BCol, BCard, BListGroup, BCardText, BFormFile, BListGroupItem, BBadge, BOverlay, BCardBody, BLink, BButton, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody, BTable } from "bootstrap-vue";
export default {
  components: { vSelect, BFormTextarea, VBModal, BRow, BImg, BCol, BCard, BCardText, BListGroup, BFormFile, BListGroupItem, BBadge, BCardBody, BLink, BButton, BOverlay, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody, BTable },
  data() {
    return {
      show: true,
      tournament_id: this.$route.params.tournament,
      tournament: { players: {} },
      players: [],
      available_players: [],
      selected_player_available: null,
      selected_player: null,
      registered: false,
    }
  },
  created() {
    this.$http.get("/api/getTournament?id=" + this.tournament_id).then((response) => {
      this.tournament = response.data;
      this.show = false;

      // verifica si jugador conectado ya está inscrito al torneo
      for (let i = 0; i < this.tournament.players.length; i++) {
        const element = this.tournament.players[i];
        if (element.id == this.user.player.id) {
          this.registered = true;
        }
      }

    })
    this.$http.get("/api/getPlayers?player_id=" + this.user.player.id + '&tournament_id=' + this.tournament_id).then((response) => {
      this.players = response.data['players'];
      this.available_players = response.data['available_players'];
      this.show = false
    })

  },

  methods: {
    getOptionKey(option) {
      return option ? option.id : null;
    },
    openRegisterModal() {
      this.$bvModal.show('register-modal');
    },
    registerPlayer: async function () {
      let that = this;
      var formData = new FormData(this.$refs.formRegister);
      formData.set('selected_player', this.selected_player ? this.selected_player.id : null);
      formData.set('selected_player_available', this.selected_player_available ? this.selected_player_available.player_id : null);

      await this.$http({
        method: "post",
        url: "/api/registerPlayer",
        data: formData
      }).then((response) => {
        console.log(response.data);
        that.tournament = response.data;
        this.selected_player = null;
        this.selected_player_available = null;
        this.registered = true;
        that.$bvToast.toast('Inscripción realizada con éxito', { variant: 'success', noCloseButton: true });
        that.$bvModal.hide('register-modal');
      });
    },


    formattedTime(hour) {
      // Usando Moment.js para dar formato a la fecha
      return moment(hour, 'HH:mm').format('HH:mm'); // Formato de 12 horas con AM/PM
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD-MM-YYYY');
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.color-american {
  background-color: rgb(40, 218, 17);
}

.color-canarian {
  background-color: rgb(17, 144, 218);
}

.custom-modal {
  overflow: hidden;
  /* Evita el desplazamiento del contenedor principal */
}

.margin-top {
  margin-top: 8%;
}
</style>
